<template>
  <div>
    <form class="form-horizontal" role="form">
      <b-form-group
        id="example text"
        label-cols-sm="2"
        label-cols-lg="2"
        label="Nome"
        description="Nome completo: João da Silva"
        label-for="text"
      >
        <b-form-input for="text" value="João"></b-form-input>
      </b-form-group>

      <b-form-group
        id="example-search"
        label-cols-sm="2"
        label-cols-lg="2"
        label="E-mail"
        description="E-mail do usuário: teste@teste.com.br"
        label-for="search"
      >
        <b-form-input
          id="search"
          value="teste@teste.com.br"
          type="search"
          name="search"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="example-email"
        label-cols-sm="2"
        label-cols-lg="2"
        label="Documento"
        description="CPF ou CNPJ: 123.456.789-00"
        label-for="email"
      >
        <b-form-input id="email" value="123456789"></b-form-input>
      </b-form-group>

      <b-form-group
        id="example-url"
        label-cols-sm="2"
        label-cols-lg="2"
        label="Nascimento"
        description="Data de nascimento: 13/12/1993"
        label-for="url"
      >
        <b-form-input
          id="url"
          value="13/12/1993"
          type="url"
          name="url"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="example-tel"
        label-cols-sm="2"
        label-cols-lg="2"
        label="Endereço"
        description="Endereço completo: Rua teste, 123"
        label-for="tele"
      >
        <b-form-input
          id="tele"
          value="Rua teste, 123"
          type="tel"
          name="tel"
        ></b-form-input>
      </b-form-group>
      <br />
      <div class="row">
        <div class="col-2"><br /><br /></div>
        <div class="col-4">
          <button
            type="button"
            @click="$emit('close', item)"
            class="btn btn-block btn-outline-dark"
          >
            Cancelar
          </button>
        </div>
        <div class="col-6">
          <button
            type="button"
            @click="saveButton"
            class="btn btn-block btn-success"
          >
            Salvar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  data() {
    return {
      title: "Novo Crud",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "FAQs",
          active: true,
        },
      ],
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    edit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    target: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    alertText() {
      const newText = "Item criado com sucesso.";
      const editText = "Item editado com sucesso.";
      return this.item && this.item.value ? editText : newText;
    },
  },
  methods: {
    async saveButton() {
      // Colocar a função de editar

      // Colocar a função de salvar o novo
      /* this.savePermission(); */
      await new Promise((r) => setTimeout(() => r(), 1000));

      Swal.fire({
        title: "Sucesso!",
        text: this.alertText,
        icon: "success",
        target: document.getElementById(this.target),
        confirmButtonText: "OK",
        confirmButtonColor: "#5cb85c",
      }).then(() => {
        this.$emit("close", this.item);
      });
    },
  },
};
</script>
