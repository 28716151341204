<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h1>Crud</h1>

            <div class="row mb-4 align-items-center">
              <div class="col-md-5">
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <input
                      type="text"
                      placeholder="Pesquisar...."
                      class="form-control"
                    />
                    <span class="d-block d-md-none"></span>
                  </div>
                  <div class="col-6 col-md-3">
                    <button class="btn btn-light btn-block">
                      <i class="fa fa-search"></i> Buscar
                    </button>
                  </div>
                  <div class="col-6 col-md-3">
                    <button class="btn btn-light btn-block">
                      <i class="fa fa-broom"></i> Limpar
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-7 mb-2 text-right align-items-center">
                <span class="d-block d-md-none p-2"> </span>
                <div>
                  <button class="btn btn-primary" @click="showNewForm = true">
                    <i class="fa fa-plus"></i> Novo
                  </button>
                </div>
                <div v-show="showNewForm">
                  <div class="box-form text-left">
                    <div id="form-novo-edit">
                      <i
                        @click="showNewForm = false; cleanForm();"
                        class="fa float-right fa-2x fa-eye-slash"
                      ></i>

                      <h1 class="mt-5">Novo Crud</h1>
                      <div>
                        <Crud @close="close" target="form-novo-edit" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <b-pagination
                  value="1"
                  :total-rows="40"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
              <div class="col-md-6 text-right">Página 1 de 2</div>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Documento</th>
                  <th>Nascimento</th>
                  <th>Endereço</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in itemsX">
                  <tr :key="item.value">
                    <td>{{ item.value }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.document }}</td>
                    <td>{{ formatDate(item.birth) }}</td>
                    <td>{{ item.address }}</td>
                    <td>
                      <div class="btn-group">
                        <VerCrud :item="item" />
                        <button
                          @click="edit(item)"
                          class="btn pl-4 pr-4 btn-sm btn-info"
                        >
                          <i class="fa fa-edit"></i> Editar
                        </button>
                        <button
                          @click="remove"
                          class="btn pl-4 pr-4 btn-sm btn-warning"
                        >
                          <i class="fa fa-trash"></i> Remover
                        </button>
                        <button
                          @click="deleteItem"
                          class="btn pl-4 pr-4 btn-sm btn-danger"
                        >
                          <i class="fa fa-trash"></i> Excluir
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr :key="'item-' + item.value" v-if="item.showEdit">
                    <td colspan="7">
                      <div>
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-8">
                            <h2><strong>Editando: </strong>{{ item.label }}</h2>
                            <Crud :item="item" @close="close" />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div class="row">
              <div class="col-md-6">
                <b-pagination
                  value="1"
                  :total-rows="40"
                  :per-page="20"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
              <div class="col-md-6 text-right">Página 1 de 2</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";

import { formatDate } from "@/utils/Date";
import Swal from "sweetalert2";
import VerCrud from "@/components/crud/VerCrud.vue";
import Crud from "@/components/crud/Crud.vue";
export default {
  page: {
    title: "Exemplo 1",
    meta: [{ name: "description" }],
  },
  components: { Layout, PageHeader, VerCrud, Crud },
  data() {
    return {
      showNewForm: false,
      itemsX: [
        {
          value: 1,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 2,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 3,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 4,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 5,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 6,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 7,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 8,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 9,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
        {
          value: 10,
          name: "João",
          email: "teste@teste.com.br",
          document: "123456789",
          birth: new Date(),
          address: "Rua teste, 123",
          showEdit: false,
        },
      ],
      title: "Exemplo 1",
      items: [
        {
          text: "Config",
          href: "/",
        },
        {
          text: "Crud",
          active: true,
        },
      ],
    };
  },
  methods: {
    formatDate,
    close(item) {
      if (item && item.value) {
        item.showEdit = false;
      } else {
        this.showNewForm = false;
      }
    },
    edit(item) {
      item.showEdit = !item.showEdit;
    },
    remove() {
      Swal.fire({
        title: "Atenção!",
        text: "Deseja mesmo remover este item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, mover pra lixeira!",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire(
            "Removido!",
            "Item movido pra lixeira com sucesso.",
            "success"
          );
        }
      });
    },
    deleteItem() {
      Swal.fire({
        title: "Atenção!",
        text: "Deseja mesmo excluir este item? isso não poderá ser desfeito!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, excluir!",
      });
    },
  },
};
</script>

<style>
#form-novo-edit {
  position: relative;
  min-height: 100vh;
}
#form-novo-edit .swal2-container {
  position: absolute !important;
}
body {
  overflow: visible !important;
}
</style>
