<template>
  <div>
    <b-button
      v-b-modal="'my-modal-' + item.value"
      class="btn pl-4 pr-4 btn-sm btn-primary"
      ><i class="fa fa-eye"></i> Ver</b-button
    >

    <b-modal :id="'my-modal-' + item.value" :title="item.label">
      <p class="my-4">#{{ item.value }}</p>
      <p class="my-4">{{ item.label }}</p>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: { item: { type: Object, default: () => {} } },
};
</script>

<style scoped lang="scss"></style>
